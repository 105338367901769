import { defaultLocale, symbolHeaderAcceptLanguage } from '@/i18n/constants';
import { useLocaleConfig } from '@/i18n/hooks/use-locale-config/use-locale-config';

export function useFetch() {
  const localeConfigContext = useLocaleConfig();

  return (...args: Parameters<typeof fetch>) => {
    const [path, options] = args;
    const headers = new Headers(options?.headers);

    headers.set('Content-Type', 'application/json');
    /**
     * NOTE: This is a temporary solution to fix the issue with the Algolia API
     * where it does not allow the `X-Accept-Language` header.
     */
    if (
      /^(?!.*algolia).*$/i.test(path.toString()) &&
      localeConfigContext.locale &&
      localeConfigContext.defaultLocale
    ) {
      headers.set(
        symbolHeaderAcceptLanguage,
        [
          localeConfigContext.locale,
          localeConfigContext.defaultLocale,
          defaultLocale,
        ].join(', '),
      );
    }

    return fetch(path, {
      ...options,
      headers,
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res;
      })
      .then((res: Response) => {
        //TODO: refactor to not use hacky 'startsWith'
        return res.status.toString().startsWith('2')
          ? res.text().then(function (text) {
              return text?.trim() ? JSON.parse(text) : {};
            })
          : { success: true };
      });
  };
}
